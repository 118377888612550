import { EContainerSubTypologyCarousel, EContainerSubTypologyPage } from 'models/container/enumeration'

export enum EContentType {
  AUDIO_RECORDING = 'AudioRecording',
  AUDIO_SHOW = 'AudioShow',
  AUDIO_SEASON = 'AudioSeason',
  AUDIO_STREAM = 'TritonDigitalAudioStream',
  CUSTOM_PAGE = 'CustomPageContent',
  IMAGE_CONTENT = 'ImageContent',
  STORY_CONTENT = 'StoryContent',
  VIDEO_RECORDING = 'VideoRecording',
  VIDEO_STREAM = 'VideoStream',
  VIDEO_SEASON = 'VideoSeason',
  VIDEO_SHOW = 'VideoShow',
  VIDEO_PARENT = 'VideoParent',
  CONTENT_GROUP = 'ContentGroup',
  TEXT_CONTENT = 'TextContent',
  AUTHOR_CONTENT = 'PersonalInformation',
  CHANNEL_CONTENT = 'ChannelContent',
  EXTERNAL_LINK_CONTENT = 'ExternalLinkContent',
  SPONSOR_CONTENT = 'SponsorContent',
  PAGE_CONTENT = 'PageContent',
  POSTER_CONTENT = 'PosterContent',
  FEED_CONTENT = 'FeedContent',
  CAROUSEL_CONTENT = 'CarouselContent',
  BOOK_CONTENT = 'BookContent',
  BOOK_VARIANT_CONTENT = 'BookVariantContent',
  MENU = 'MenuContent',
  CONTEST = 'ContestContent',
  WIDGET_CONTENT = 'WidgetContent',
}

export type TContainerSubtypology = EContainerSubTypologyPage | EContainerSubTypologyCarousel

export type TContentSubtypology =
  | EExternalLinkSubTypology
  | EVideoParentSubTypology
  | EAudioSubTypology
  | EContentSubTypologyBookVariant
  | EVideoSubTypology
  | EStorySubTypology
  | EPageSubTypology
  | EFeedSubTypology
  | ECarouselSubTypology

export type TSubtypology = TContainerSubtypology | TContentSubtypology

export enum EContentTypology {
  AUDIO = 'AUDIO',
  AUDIO_SEASON = 'AUDIO_SEASON',
  AUDIO_STREAM = 'AUDIO_STREAM',
  AUDIO_SHOW = 'AUDIO_SHOW',
  CAROUSEL = 'CAROUSEL',
  CHANNEL = 'CHANNEL',
  CONTEST = 'CONTEST',
  CUSTOM_PAGE = 'CUSTOM_PAGE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  IMAGE = 'IMAGE',
  PAGE = 'PAGE',
  POSTER = 'POSTER',
  STORY = 'STORY',
  VIDEO = 'VIDEO',
  VIDEO_SEASON = 'VIDEO_SEASON',
  VIDEO_SHOW = 'VIDEO_SHOW',
  VIDEO_STREAM = 'VIDEO_STREAM',
  VIDEO_PREVIEW = 'VIDEO_PREVIEW',
  VIDEO_COMING_SOON = 'VIDEO_COMING_SOON',
  VIDEO_DOCUMENTARY = 'VIDEO_DOCUMENTARY',
  VIDEO_MOVIE = 'VIDEO_MOVIE',
  VIDEO_OTHER = 'VIDEO_OTHER',
  VIDEO_PERFORMANCE = 'VIDEO_PERFORMANCE',
  VIDEO_SHOW_DOCUMENTARY = 'VIDEO_SHOW_DOCUMENTARY',
  VIDEO_SHOW_MOVIE = 'VIDEO_SHOW_MOVIE',
  VIDEO_SHOW_PERFORMANCE = 'VIDEO_SHOW_PERFORMANCE',
  VIDEO_SHOW_SHOW = 'VIDEO_SHOW_SHOW',
  FEED = 'FEED',
  PODCAST_TRAILER = 'PODCAST_TRAILER',
  PODCAST_BONUS = 'PODCAST_BONUS',
  PODCAST_EPISODE = 'PODCAST_EPISODE',
  RADIO_UNCUT = 'RADIO_UNCUT',
  RADIO_SEGMENT = 'RADIO_SEGMENT',
  PAGE_SPECIAL = 'PAGE_SPECIAL',
  PAGE_GENRE = 'PAGE_GENRE',
  PAGE_EVENT = 'PAGE_EVENT',
  PAGE_HOME = 'PAGE_HOME',
  BOOK = 'BOOK',
  BOOK_VARIANT = 'BOOK_VARIANT',
  // TEXT is not a real typology, but TextContent is the only content without a typology,
  // throwing off the rest of TS type inference.
  // A real fix would be to have the backend also define TextContent with a typology.
  TEXT = 'TEXT',
  MENU = 'MENU',
  WIDGET = 'WIDGET',
}

export enum EContentDisplayableTagLabel {
  FREE_EPISODE = 'Épisode gratuit',
  ONE_FREE_EPISODES = '1 épisode gratuit',
  TWO_FREE_EPISODES = '2 épisodes gratuits',
  THREE_FREE_EPISODES = '3 épisodes gratuits',
  FREE_EPISODES = 'Épisodes gratuits',
  NEW_SEASONS = 'Nouvelles saisons',
  NEW_EPISODES = 'Nouveaux épisodes',
  NEW_EPISODE = 'Nouvel épisode',
  DISCOVERY = 'À découvrir',
  DO_NOT_MISS = 'À ne pas manquer',
  SOON = 'À venir',
  TRAILER = 'Bande-annonce',
  VIDEO_TEASER = 'Capsule vidéo',
  EDITORIAL_CHOICE = 'Choix de la rédaction',
  COLUMN = 'Chronique',
  PARTNERSHIP = 'Collaboration',
  COLLECTION = 'Collection',
  SPONSORED = 'Commandité',
  EXCLUSIVE_CONTENT = 'Contenu exclusif',
  FAVORITE = 'Coup de coeur',
  LAST_CHANCE = 'Dernière chance',
  UNVEIL = 'Dévoilement',
  SPECIAL = 'Dossier spécial',
  ENGLISH_CONTENT = 'En anglais',
  UNSCRAMBLING = 'En débrouillage',
  DIRECT = 'En direct',
  TO_BINGE_WATCH = 'En rafale',
  INTERVIEW = 'Entrevue',
  EXCLUSIVE_INTERVIEW = 'Entrevue exclusive',
  EXCLUSIVE = 'Exclusif',
  EXCLUSIVITY = 'Exclusivité',
  EXCLUSIVITIES = 'Exclusivités',
  TEASER = 'Extrait',
  SEASON_FINAL_EPISODE = 'Finale de saison',
  FINAL_EPISODE = 'Finale',
  FREE = 'Gratuit',
  NEW_CONTENT = 'Nouveauté',
  NEW_IMAGES = 'Nouvelles images',
  NEW_SEASON = 'Nouvelle saison',
  OPEN_TO_EVERYONE = 'Ouvert à tous',
  PARTNERS = 'Partenaires',
  ONLY_ON_OUR_PLATFORM = 'Primeur',
  COVERAGE = 'Reportage',
  COMPLETE_SEASON = 'Saison complète',
  AUDIO_SERIE = 'Série audio',
  FULL_SERIE = 'Série complète',
  ORIGINAL_SERIE = 'Série originale',
  ADVERTISING_SERIE = 'Série publicitaire',
  VIDEO_SERIE = 'Série vidéo',
  ON_THE_ROAD = 'Sur la route',
  MUSIC_VIDEO = 'Vidéoclip',
  WEB_SERIES = 'Websérie',
}

export enum EContentUpdateType {
  CONTENT_UPDATE = 'ContentUpdate',
  VIDEO_SHOW_UPDATE = 'VideoShowUpdate',
  VIDEO_SEASON_UPDATE = 'VideoSeasonUpdate',
  AUDIO_SHOW_UPDATE = 'AudioShowUpdate',
  VIDEO_UPDATE = 'VideoUpdate',
  AUDIO_UPDATE = 'AudioUpdate',
  EXTERNAL_LINK_UPDATE = 'ExternalLinkUpdate',
  PAGE_UPDATE = 'PageUpdate',
}

export enum EFeedSubTypology {
  SOURCE = 'SOURCE',
  PRIMARY_CATEGORY = 'PRIMARY_CATEGORY',
  SECONDARY_CATEGORY = 'SECONDARY_CATEGORY',
  STANDARD = 'STANDARD',
  REGIONALIZED = 'REGIONALIZED',
}

export enum ECarouselSubTypology {
  COMBO = 'COMBO',
  HERO = 'HERO',
  LIST = 'LIST',
  MOSAIC = 'MOSAIC',
  STANDARD = 'STANDARD',
}

export enum EAudioSubTypology {
  PODCAST_BONUS = 'PODCAST_BONUS',
  PODCAST_EPISODE = 'PODCAST_EPISODE',
  PODCAST_TRAILER = 'PODCAST_TRAILER',
  RADIO_BONUS = 'RADIO_BONUS',
  RADIO_SEGMENT = 'RADIO_SEGMENT',
  RADIO_TRAILER = 'RADIO_TRAILER',
  RADIO_UNCUT = 'RADIO_UNCUT',
}

export enum EStorySubTypology {
  STORY = 'STORY',
  FIVE_MINUTES = 'FIVE_MINUTES',
  COLUMN = 'COLUMN',
  CARTOON = 'CARTOON',
  CENTER_TEXT = 'CENTER_TEXT',
  LEFT_TEXT = 'LEFT_TEXT',
  RIGHT_TEXT = 'RIGHT_TEXT',
  TIMELINE = 'TIMELINE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  HUBLO = 'HUBLO',
  BLOG_POST = 'BLOG_POST',
  CUBE = 'CUBE',
  STREET_REPORT = 'STREET_REPORT',
  REVIEW = 'REVIEW',
  LETTERS_TO_THE_EDITOR = 'LETTERS_TO_THE_EDITOR',
  EDITORIAL = 'EDITORIAL',
  PRESS_CONFERENCE = 'PRESS_CONFERENCE',
  SPECIAL_EVENT = 'SPECIAL_EVENT',
  GALA = 'GALA',
  VENUE_EVENT = 'VENUE_EVENT',
  INTERACTIVE = 'INTERACTIVE',
}

export enum EPageSubTypology {
  CATCH_UP = 'CATCH_UP',
  EVENT = 'EVENT',
  GENRE = 'GENRE',
  HOME = 'HOME',
  SPECIAL = 'SPECIAL',
  THEME = 'THEME',
}

export enum EVideoSubTypology {
  PLAIN = 'PLAIN',
  EPISODE = 'EPISODE',
  TRAILER = 'TRAILER',
  CLIP = 'CLIP',
  EXCLUSIVE = 'EXCLUSIVE',
  WEB_SERIES = 'WEB_SERIES',
  CAPSULE = 'CAPSULE',
  EXCLUSIVITY = 'EXCLUSIVITY',
  MOVIE = 'MOVIE',
  PERFORMANCE = 'PERFORMANCE',
  DOCUMENTARY = 'DOCUMENTARY',
  PREVIEW = 'PREVIEW',
  EDITORIAL = 'EDITORIAL',
  INTERVIEW = 'INTERVIEW',
  SEGMENT = 'SEGMENT',
  COMING_SOON = 'COMING_SOON',
}

export enum EVideoParentSubTypology {
  DOCUMENTARY = 'DOCUMENTARY',
  MOVIE = 'MOVIE',
  PERFORMANCE = 'PERFORMANCE',
  SHOW = 'SHOW',
}

export enum EExternalLinkSubTypology {
  PLAIN = 'PLAIN',
  SPONSOR = 'SPONSOR',
}

export enum EContentState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ORIGIN_INACTIVE = 'ORIGIN_INACTIVE',
  ERROR = 'ERROR',
  DELETED = 'DELETED',
  INCOMPLETE = 'INCOMPLETE',
  EMBARGO = 'EMBARGO',
  EXPIRED = 'EXPIRED',
}

export enum EStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EContentOriginSystem {
  AIM = 'AIM',
  EMP = 'EMP',
  CUBE = 'CUBE',
  OMNY = 'OMNY',
  BRIGHTCOVE = 'BRIGHTCOVE',
  PFU = 'PFU',
  SWS = 'SWS',
  SHOPIFY = 'SHOPIFY',
  RSS = 'RSS',
}

export enum EContentSource {
  JDM = 'jdm',
  JDQ = 'jdq',
  TVAN = 'tvan',
  TVAS = 'tvas',
  TVAS2 = 'tvas2',
  TVA = 'tva',
  TVAPLUS = 'tvaplus',
  ELLE = 'elle',
  CDEP = 'coupdepouce',
  VQH = '24h',
  QUB = 'qub',
  QUBR = 'qubradio',
  QUBI = 'qubinfo',
  QUBL = 'qublivre',
  QUBTV = 'qubtv',
  NA = 'na',
  ADDIKTV = 'addiktv',
  CASA = 'casa',
  PRISE2 = 'prise2',
  TEMOIN = 'temoin',
  PSS = 'pss',
  SDC = 'sdc',
  BILLIE = 'billiejean',
  SILO57 = 'silo57',
  SB = 'sb',
  CLINDOEIL = 'clindoeil',
  SEPTJOURS = '7jours',
  VINGT_QUATRE_HEURES = '24heures',
  EVASION = 'evasion',
  ZESTE = 'zeste',
  LCN = 'lcn',
  VRAI = 'vrai',
  HUBLO = 'hublo',
  PORTEMONNAIE = 'portemonnaie',
  BUREAUDENQUETE = 'bureaudenquete',
  EN5MINUTES = 'en5minutes',
  CLUB_ILLICO = 'clubillico',
}

export enum ETenant {
  QUB = 'QUB',
  JDM = 'JDM',
  JDQ = 'JDQ',
  TVAN = 'TVAN',
  TVAPLUS = 'TVAPLUS',
  TVAS = 'TVAS',
  CLUB_ILLICO = 'CLUB_ILLICO',
}

export enum EContentOwner {
  JDM = 'JDM',
  JDQ = 'JDQ',
  INFO = 'INFO',
  LIVRE = 'LIVRE',
  RADIO = 'RADIO',
  TVAN = 'TVAN',
  VRAI = 'VRAI',
  TVAPLUS = 'TVAPLUS',
  TVAS = 'TVAS',
  CLUB_ILLICO = 'CLUB_ILLICO',
}

export enum EContentPFUSource {
  QUBR = 'qubradio',
  QUBI = 'qubinfo',
  QUBL = 'qublivre',
  TVAPLUS = 'tvaplus',
  VRAI = 'vrai',
  CLUB_ILLICO = 'clubillico',
}

export enum EContentBrightcoveAccountName {
  TVA = 'tva',
  TVACA = 'TVA - TVA.ca',
  ILLICO = 'illico',
  TVAN = 'TVA - LCN',
}

export enum EContentBrightcoveStudios {
  ADDIKTV = 'addiktv',
  CASA = 'casa',
  LCN = 'lcn',
  TEMOIN = 'Témoin',
  TVA = 'tva',
  TVAS = 'tva sports',
  ZESTE = 'zeste',
  EVASION = 'évasion',
  PRISE2 = 'prise 2',
  CLUB_ILLICO = 'Illico+',
  COMMANDITE = 'commandite',
  GROUPE_MUSIQUE = 'groupe musique',
  BILLIE = 'billie',
  DISQUE_DUR = 'disque dur',
  LE_GUIDE_DE_LAUTO = "le guide de l'auto",
  EN_5MINUTES = 'en 5 minutes',
  LE_SAC_DE_CHIPS = 'le sac de chips',
  JDM = 'le journal de montréal',
  JDQ = 'le journal de québec',
  PESE_SUR_START = 'pèse sur start',
  PORTE_MONNAIE = 'porte-monnaie',
  SILO_57 = 'silo 57',
  FOODLAVIE = 'foodlavie',
  RECETTES_DU_QUEBEC = 'recettes du québec',
  CLIN_DOEIL = "clin d'œil",
  CLIN_DOEIL_2 = "Clin d'oeil",
  COUP_DE_POUCE = 'coup de pouce',
  LA_SEMAINE = 'la semaine',
  LES_IDEES_DE_MA_MAISON = 'les idées de ma maison',
  SEPT_JOURS = '7 jours',
  TVA_CA = 'tva.ca',
  MATV = 'MAtv',
  VRAI = 'VRAI',
  ILLICO = 'Illico',
}

export enum EContentBrightcoveGenres {
  INFORMATIONS_ET_DIVERTISSEMENT = 'Informations et divertissement',
  INFORMATIONS = 'Informations',
  SERIES = 'Séries',
  VARIETES_ET_HUMOUR = 'Variétés et humour',
  DOCUREALITES_ET_DOCUMENTAIRES = 'Docu-réalités et documentaires',
  STYLE_DE_VIE = 'Style de vie',
  JEUNESSE = 'Jeunesse',
  SPORTS = 'Sports',
  HUMOUR = 'Humour',
  SERIE_PUBLICITAIRE = 'Série publicitaire',
  TELEREALITE_ET_VARIETE = 'Téléréalités et variétés',
}

export enum EContentBrightcoveIllicoGenres {
  ACTION_AND_ADVENTURE = 'Action et aventure',
  LIFESTYLE = 'Art de Vivre',
  COMEDY = 'Comédie',
  DOCUMENTARY = 'Documentaire',
  DRAMA = 'Drame',
  HORROR = 'Horreur',
  NEWS = 'Information',
  KIDS_AND_FAMILY = 'Jeunesse et Famille',
  CRIME = 'Policier',
  ROMANCE = 'Romance',
  SCIENCE_FICTION = 'Science-fiction',
  SPORT = 'Sport',
  THRILLER = 'Suspense',
  REALITY_TV = 'Télé-réalité',
  VARIETY = 'Variétés',
  STANDUP = 'Humour',
}

export enum ERelationshipType {
  CONTAINED_IN = 'CONTAINED_IN',
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  PROMO_DESCRIPTION = 'PROMO_DESCRIPTION',
  OBJECT = 'OBJECT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  CROP = 'CROP',
  LEAD = 'LEAD',
  STRAPLINE = 'STRAPLINE',
  SUBTITLE = 'SUBTITLE',
  AUTHOR = 'AUTHOR',
  BODY = 'BODY',
  TEXT = 'TEXT',
  RELATED = 'RELATED',
  PROGRAM = 'PROGRAM',
  EPISODE = 'EPISODE',
  EPISODE_NUMBER = 'EPISODE_NUMBER',
  SHOW = 'SHOW',
  SHOW_NAME = 'SHOW_NAME',
  SEASON = 'SEASON',
  SEASON_NUMBER = 'SEASON_NUMBER',
  HIERARCHY_DISPLAY_ORDER = 'HIERARCHY_DISPLAY_ORDER',
  ORIGIN_NAME = 'ORIGIN_NAME',
}

export enum ERelationshipQualifier {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  THUMBNAIL = 'THUMBNAIL',
  POSTER = 'POSTER',
  SHORT = 'SHORT',
  LONG = 'LONG',
  HEADLINE = 'HEADLINE',
  LEAD = 'LEAD',
  WEB = 'WEB',
  MOBILE = 'MOBILE',
  _16X9 = '16x9',
  _2X3 = '2x3',
}

export enum EVideoSpecificFields {
  BRIGHCOVEID = 'brightcove-id',
  REFERENCEID = 'reference-id',
  GENRE = 'genre',
  RELEASEDATE = 'releasedate',
  LONGDESCRIPTION = 'longdescription',
  VIDEOSHARE = 'videoShare',
  ACCESSLEVEL = 'accesslevel',
  CAST = 'cast',
  DIRECTOR = 'director',
  ORIGIN_COUNTRY = 'originCountry',
  PARENTAL_RATING = 'parentalrating',
  EPISODE_NUMBER = 'episodenumber',
  LIBRARY_KEY = 'librarykey',
  LIBRARY_KEY_ABSTRACT = 'librarykeyabstract',
  NO_MATERIEL = 'nomateriel',
  DRM_VALUE = 'originaldrmvalue',
  SCHEDULE = 'schedule',
  GEOBLOCK = 'geoBlock',
  DURATION = 'duration',
  STUDIO = 'studio',
  DFP_SHOW = 'dfp_show',
  SHOW_NAME = 'showname',
  ITEMTYPE = 'itemtype',
  ECONOMICS = 'economics',
  SEASON_NUMBER = 'seasonnumber',
}

export enum EGroupType {
  PROGRAM = 'Program',
  EPISODE = 'episode',
  EPISODE_NUMBER = 'episode_number',
  SHOW = 'show',
  SHOW_NAME = 'show_name',
  SEASON = 'season',
  SEASON_NUMBER = 'season_number',
}

export enum EHierarchyDisplayOrderType {
  CHRONOLOGICAL = 'CHRONOLOGICAL',
  REVERSE_CHRONOLOGICAL = 'REVERSE_CHRONOLOGICAL',
}

export enum EDetailsMenuTextColor {
  BLACK = 'BLACK',
  WHITE = 'WHITE',
}

export enum EContentSubTypeFeed {
  STANDARD = 'STANDARD',
}

export enum EContentSubTypologyBookVariant {
  PAPERBACK = 'PAPERBACK',
  UNKNOWN = 'UNKNOWN',
}

export enum ERecordType {
  AUDIO = 'AUDIO',
  AUDIO_SEASON = 'AUDIO_SEASON',
  AUDIO_SHOW = 'AUDIO_SHOW',
  AUDIO_STREAM = 'AUDIO_STREAM',
  AUDIO_UNKNOWN = 'AUDIO_UNKNOWN',
  CHANNEL = 'CHANNEL',
  CHANNEL_CONTAINER = 'CHANNEL_CONTAINER',
  COMBO = 'COMBO',
  CONTEST = 'CONTEST',
  REGIONALIZED = 'REGIONALIZED',
  CUSTOM_PAGE = 'CUSTOM_PAGE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  GENRE = 'GENRE',
  HERO = 'HERO',
  HOME = 'HOME',
  MOSAIC = 'MOSAIC',
  LIST = 'LIST',
  PAGE = 'PAGE',
  PAGE_CATCH_UP = 'PAGE_CATCH_UP',
  PAGE_GENRE = 'PAGE_GENRE',
  PAGE_HOME = 'PAGE_HOME',
  PAGE_EVENT = 'PAGE_EVENT',
  PAGE_THEME = 'PAGE_THEME',
  PAGE_SPECIAL = 'PAGE_SPECIAL',
  PERSONALIZED_FEED = 'PERSONALIZED_FEED',
  POSTER = 'POSTER',
  SPONSOR = 'SPONSOR',
  STANDARD = 'STANDARD',
  STORY = 'STORY',
  VIDEO = 'VIDEO',
  VIDEO_SEASON = 'VIDEO_SEASON',
  VIDEO_PARENT_SHOW = 'VIDEO_PARENT_SHOW',
  VIDEO_PARENT_MOVIE = 'VIDEO_PARENT_MOVIE',
  VIDEO_PARENT_DOCUMENTARY = 'VIDEO_PARENT_DOCUMENTARY',
  VIDEO_PARENT_PERFORMANCE = 'VIDEO_PARENT_PERFORMANCE',
  VIDEO_STREAM = 'VIDEO_STREAM',
  VIDEO_PREVIEW = 'VIDEO_PREVIEW',
  VIDEO_COMING_SOON = 'VIDEO_COMING_SOON',
  FEED_SOURCE = 'FEED_SOURCE',
  FEED_STANDARD = 'FEED_STANDARD',
  FEED_PRIMARY_CATEGORY = 'FEED_PRIMARY_CATEGORY',
  FEED_REGIONALIZED = 'FEED_REGIONALIZED',
  RADIO_UNCUT = 'RADIO_UNCUT',
  RADIO_SEGMENT = 'RADIO_SEGMENT',
  RADIO_TRAILER = 'RADIO_TRAILER',
  RADIO_BONUS = 'RADIO_BONUS',
  PODCAST_TRAILER = 'PODCAST_TRAILER',
  PODCAST_BONUS = 'PODCAST_BONUS',
  PODCAST_EPISODE = 'PODCAST_EPISODE',
  TEMPLATE = 'TEMPLATE',
  BOOK_VARIANT_PAPERBACK = 'BOOK_VARIANT_PAPERBACK',
  BOOK_VARIANT_UNKNOWN = 'BOOK_VARIANT_UNKNOWN',
  MENU = 'MENU',
}

export const ContentRelationTypeLabel: Record<string, string> = {
  CONTENANTS: 'Carrousels, Pages, Fils',
  LINKEDCONTENTS: 'Contenues liés',
  PARENTCONTENTS: 'Pages, Fils',
}
