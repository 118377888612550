import * as querystring from 'querystring'

import { IContentsConfig } from 'models/container/container'
import { EContainerType, EContainerTypology } from 'models/container/enumeration'
import { IContentContainer, IRelationsGroup, TContent } from 'models/content/content'
import { EContentType } from 'models/content/enumeration'
import IContent, { IContentWithContext } from 'models/detail/content/Content'
import { IAssociation } from 'models/detail/content/ContentAssociation'
import {
  IContentCarouselCreateParams,
  IContentCreateParams,
  IContentMenuCreateParams,
  IContentPageCreateParams,
  IExternalLinkCreateParams,
  IPosterCreateParams,
  IWidgetCreateParams,
} from 'models/detail/content/ContentCreate'
import { IContentImageUpdate, TContentUpdateParams } from 'models/detail/content/ContentUpdate'
import IShowHierarchy from 'models/detail/content/ShowHierarchy'
import { EContext } from 'models/detail/Enumeration'
import { EMenuBackgroundImageRatio } from 'modules/menu/menu.def'
import AxiosContentUIService from 'services/AxiosContentUIService'

const withContext = (content: IContent): IContentWithContext => ({
  ...content,
  __context: EContext.CONTENT,
})

export const fetchContentApi = (id: string): Promise<IContent> =>
  AxiosContentUIService.Instance.get(`/contents/${id}`).then((response) => withContext(response.data))

export const fetchContentAssociationsApi = (id: string): Promise<TContent[]> =>
  AxiosContentUIService.Instance.get<TContent[]>(`/associations/content/${id}`).then((response) => response.data)

export const updateContentApi = (
  id: string,
  body: TContentUpdateParams,
  image?: IContentImageUpdate
): Promise<IContent> =>
  AxiosContentUIService.Instance.patch<IContent>(`/contents/${id}`, { ...body, image }).then((response) =>
    withContext(response.data)
  )

export const updateContentWithImagesApi = (
  id: string,
  body: TContentUpdateParams,
  image?: IContentImageUpdate,
  image16X9?: IContentImageUpdate,
  image7X1?: IContentImageUpdate,
  image3X1?: IContentImageUpdate,
  image2X3?: IContentImageUpdate,
  showCaseImage16X9Url?: string,
  showCaseImage2X3Url?: string,
  backgroundImage73By5?: string,
  backgroundImage16By6?: string,
  headerImage?: string
): Promise<IContent> => {
  const backgroundImagesBuilder = []
  backgroundImage73By5 &&
    backgroundImagesBuilder.push({
      url: backgroundImage73By5,
      identifier: EMenuBackgroundImageRatio.BACKGROUND_LARGE_FORMAT,
    })
  backgroundImage16By6 &&
    backgroundImagesBuilder.push({
      url: backgroundImage16By6,
      identifier: EMenuBackgroundImageRatio.BACKGROUND_SMALL_FORMAT,
    })
  return AxiosContentUIService.Instance.patch<IContent>(`/contents/${id}`, {
    ...body,
    image,
    image16X9,
    image7X1,
    image3X1,
    image2X3,
    showCaseImage16X9Url,
    showCaseImage2X3Url,
    style: {
      ...body?.style,
      backgroundImages: backgroundImagesBuilder,
      headerImageUrl: headerImage,
    },
  }).then((response) => withContext(response.data))
}

export const updateContentAssociationsApi = (body: IAssociation[]): Promise<void> =>
  AxiosContentUIService.Instance.post(`/associations/`, body)

export const deleteContentAssociationsApi = (body: IAssociation[]): Promise<void> =>
  AxiosContentUIService.Instance.delete(`/associations/`, { data: body })

export const fetchContentRelatesToApi = (id: string, relationTypes?: string[]): Promise<IRelationsGroup[]> =>
  AxiosContentUIService.Instance.get<IRelationsGroup[]>(
    `/items/relatesTo/${id}${relationTypes ? '?' + querystring.stringify({ relationTypes }) : ''}`
  ).then((response) => response.data)

export const fetchContentShowHierarchyApi = (id: string): Promise<IShowHierarchy> =>
  AxiosContentUIService.Instance.get<IShowHierarchy>(`showhierarchy/${id}`).then((response) => response.data)

export const uploadImageContentApi = async (file: File): Promise<string> => {
  const formData = new FormData()
  formData.append('file', file)
  return AxiosContentUIService.Instance.post(`/contents/upload`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).then((response) => {
    return response.data
  })
}

export const createExternalLinkApi = (params: IExternalLinkCreateParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post(`/externalLink`, {
    type: params.data.type,
    title: params.data.title,
    publicTitle: params.data.publicTitle,
    url: params.data.url,
    description: params.data.description,
    image: params.data.image,
    subTypology: params.data.subTypology,
  }).then((response) => withContext(response.data))

export const createPosterApi = (params: IPosterCreateParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post(`/poster`, {
    title: params.data.title,
    publicTitle: params.data.publicTitle,
    subTitle: params.data.subTitle,
    image16X9: params.data.image16X9,
    image7X1: params.data.image7X1,
    image3X1: params.data.image3X1,
    image2X3: params.data.image2X3,
    linkedContentId: params.data.content?.id,
    callToActionEnabled: params.data.callToActionEnabled,
    callToActionText: params.data.callToActionText,
    excludedPlatforms: params.data.excludedPlatforms,
    owner: params.data?.owner,
  }).then((response) => withContext(response.data))

export const createMenuApi = ({ data }: IContentMenuCreateParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post('/menu', data).then((response) => withContext(response.data))

export const createPageApi = (params: IContentPageCreateParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post('/page', {
    type: 'PageCreate',
    name: params.data.name,
    publicTitle: params.data.publicTitle,
    description: params.data.description,
    containerAlias: params.data.alias,
    containerSubType: params.data.containerSubType,
    containerSubTypology: params.data.containerSubTypology,
    contentSource: params.data.contentSource,
    aliasLink: params.data.aliasLink,
    containerType: EContainerType.PAGE,
    containerTypology: EContainerTypology.PAGE,
    image: params.data?.image,
    owner: params.data?.owner,
    slugTail: params.data?.slugTail,
    parentReference: params.data?.parentReference,
    slots: params.data.slots,
  }).then((response) => withContext(response.data))

export const createCarouselApi = (params: IContentCarouselCreateParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post('/carousel', {
    type: 'CarouselCreate',
    name: params.data.name,
    publicTitle: params.data.publicTitle,
    description: params.data.description,
    containerAlias: params.data.alias,
    containerSubType: params.data.containerSubType,
    containerSubTypology: params.data.subTypology,
    aliasLink: params.data.aliasLink,
    containerType: EContainerType.CAROUSEL,
    containerTypology: EContainerTypology.CAROUSEL,
    linkedContentId: params.data.linkedContentId,
    useLinkedContentAsSource: params.data.useLinkedContentAsSource,
    owner: params.data?.owner,
    slugTail: params.data?.slugTail,
    parentReference: params.data?.parentReference,
    slots: params.data.slots,
    mirroredContentId: params.data?.mirroredContentId,
    mosaicRowsXS: params.data?.mosaicRowsXS,
    mosaicRowsS: params.data?.mosaicRowsS,
    mosaicRowsL: params.data?.mosaicRowsL,
    excludedPlatforms: params.data?.excludedPlatforms,
    canBeDeletedOrDuplicated: params.data?.canBeDeletedOrDuplicated,
  }).then((response) => withContext(response.data))

export const createWidgetApi = (params: IWidgetCreateParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post(`/widget`, {
    type: params.data.type,
    title: params.data.title,
    publicTitle: params.data.publicTitle,
    iframeUrl: params.data.iframeUrl,
    targetUrl: params.data.targetUrl,
    description: params.data.description,
    image: params.data.image,
  }).then((response) => withContext(response.data))

export const createContentApi = ({ data }: IContentCreateParams): Promise<void | IContent | IContentContainer> => {
  switch (data.type) {
    case EContentType.EXTERNAL_LINK_CONTENT:
      return createExternalLinkApi({ data })
    case EContentType.POSTER_CONTENT:
      return createPosterApi({ data })
    case EContentType.PAGE_CONTENT:
      return createPageApi({ data })
    case EContentType.MENU:
      return createMenuApi({ data })
    case EContentType.CAROUSEL_CONTENT:
      return createCarouselApi({ data })
    case EContentType.WIDGET_CONTENT:
      return createWidgetApi({ data })
    default:
      return new Promise(null)
  }
}

export const deleteContentApi = (id: string): Promise<void> =>
  AxiosContentUIService.Instance.delete(`/contents/${id}`).then((response) => response.data)

export const fetchConfiguration = (config: IContentsConfig): Promise<{ contentCount: number }> => {
  return AxiosContentUIService.Instance.post(`/configuration/contents`, config).then((response) => response.data)
}
